
import {defineComponent, ref, reactive, inject} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressListOfNei.vue";
import ChooseAddressCard from "@/components/common/ChooseAddressCard.vue";
export default defineComponent({
  name: "SubmitOrder",
  components: {
    AddressList,
    Loading,
    ChooseAddressCard
  },
  setup() {
    const listAddress = ref<[] | null>(null);
    const my = ref<{} | null>(null);
    const checked1 = ref<string[]>([]);
    const loading = ref(false);
    const remarkValue = ref();
    const userInfo = inject("userInfo") as any;
    // checked1.value.push('0');
    function onSubmit() {
      // if (!checked1.value.length) {
      //   Toast({
      //     type: "warning",
      //     title: "请选择收货地址",
      //   });
      //   return false;
      // }
      let tipInfo = '下单成功！客服人员将联系您确认订单'

      if (!userInfo.realName || !userInfo.userUnit) {
        tipInfo = '下单成功！客服人员将联系您确认订单，完善资料联系更方便。'
      }
      axios
        .post(
          "/M/Server/DoCheckoutNew",
          qs.stringify({
            addressId: checked1.value[0],
            remark:remarkValue.value
          })
        )
        .then((res) => {
          if (res.data.success) {
            // localStorage.setItem("showRedPackage", "true");
            Modal({
              title: "提示",
              content: tipInfo,
              closeText: "关闭",
              onClose: () => {
                router.push({
                  name: "Order",
                  query: {
                    type: 0,

                  },
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };

    function handleDel(id: string) {
      Modal({
        title: "温馨提示",
        content: "确认删除?",
        onConfirm: () => {
          const data = qs.stringify({
            id
          });
          axios.post("/M/Server/DoDelAddress", data).then(res => {
            console.log(res)
            Toast({
              type: "success",
              title: "删除成功！",
            });
            // getList()
            window.location.reload()
          }).catch(err => {
            console.log(err)
          })
        },
        onCancel: () => {
          console.log('取消')
        },
      });
    }
    return {
      listAddress,
      checked1,
      onSubmit,
      loadOption,
      loading,
      handleDel,
      remarkValue
    };
  },
});
